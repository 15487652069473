import React from "react";
import styled from "styled-components";

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode; // Changed from text to children
  width?: string;
  fontSize?: string;
  bgColor?: string;
  textColor?: string;
  boxShadow?: string;
  borderRadius?: string;
  height?: string;
  fontWeight?: string;
  hoverBgColor?: string;
  borderColor?: string;
  disable?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  height: ${({ height }) => height || "40px"};
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ borderColor }) => borderColor || "#4b87ed"};
  border-radius: ${({ borderRadius }) => borderRadius || "5px"};
  color: ${({ textColor }) => textColor || "#4b87ed"};
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor || "none"};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight || "600"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  width: ${({ width }) => width || "230px"};

  &:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor || "#B0C4DE"};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const CompletionButton: React.FC<ButtonProps> = ({
  onClick,
  children,
  width,
  fontSize,
  bgColor,
  textColor,
  boxShadow,
  borderRadius,
  height,
  fontWeight,
  hoverBgColor,
  borderColor,
  disable = false,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      width={width}
      fontSize={fontSize}
      bgColor={bgColor}
      textColor={textColor}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
      height={height}
      fontWeight={fontWeight}
      hoverBgColor={hoverBgColor}
      borderColor={borderColor}
      disabled={disable}>
      {children}
    </StyledButton>
  );
};

export default CompletionButton;
