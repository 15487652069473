import React, { useEffect, useState } from "react";
import {
  Addon,
  AddonList,
  CreateAddOnsInput,
} from "../../../../types/graphQlQuery.interface";
import { AppCSS } from "../../../../components";
import { CustomerProjectDetails } from "../../../../redux/types/projectDetails";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_PROJECT_ADDON } from "../../../../apolloClient/operation/mutations";
import { toast } from "react-toastify";

interface AddonProps {
  project: CustomerProjectDetails;
}

export default function Addons({ project }: AddonProps) {
  const [addons, setAddons] = useState<AddonList>({ addons: [] });
  const [creating, setCreating] = useState(false);
  const [addon, setAddon] = useState<Addon>({
    projectId: project.projectId,
    addonName: "",
    description: "",
    price: 0,
  });

  const [addProjectAddOns] = useMutation<
    {
      addProjectAddons: boolean;
    },
    { input: CreateAddOnsInput }
  >(CREATE_PROJECT_ADDON);

  useEffect(() => {
    if (project) {
      const newAddons = [
        ...addons.addons,
        ...(project?.insuranceScope.addOns || []),
      ];
      setAddons({ addons: newAddons });
    }
  }, [project]);

  const saveNewItem = () => {
    addProjectAddOns({
      variables: {
        input: {
          projectId: addon.projectId,
          addonName: addon.addonName,
          description: addon.description,
          price: addon.price,
        },
      },
    })
      .then(() => {
        const newAddons = [...addons.addons, addon];
        setAddons({ addons: newAddons });
        setCreating(false);
      })
      .catch((error) => {
        toast("Error creating. Please try again.");
        setCreating(false);
      });
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h2 style={{ color: "black", fontWeight: "bold", fontSize: 18 }}>
            Create Add-On
          </h2>
          {creating ? (
            <span
              style={{
                height: 20,
                width: 20,
                marginLeft: 10,
                cursor: "pointer",
              }}
              onClick={() => setCreating(false)}
            >
              {" "}
              <img
                src="https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/fad2c84a-4ba6-4410-78c2-bd5de3eb9500/chatImage"
                height={20}
                width={20}
                style={{
                  transform: "rotate(45deg)",
                }}
              />
            </span>
          ) : (
            <span
              style={{
                height: 20,
                width: 20,
                marginLeft: 10,
                cursor: "pointer",
              }}
              onClick={() => setCreating(true)}
            >
              {" "}
              <img
                src="https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/fad2c84a-4ba6-4410-78c2-bd5de3eb9500/chatImage"
                height={20}
                width={20}
              />
            </span>
          )}
        </div>
        {creating ? (
          <div
            onClick={saveNewItem}
            style={{
              color: AppCSS.BLUE,
              borderWidth: 1,
              borderColor: AppCSS.BLUE,
              width: 150,
              height: 35,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              marginLeft: 10,
              cursor: "pointer",
            }}
          >
            <p>Create</p>
          </div>
        ) : (
          <></>
        )}
      </div>
      {creating ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 5,
            flexWrap: "wrap",
            borderWidth: 4,
            borderColor: AppCSS.BLUE,
            marginBottom: 5,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                color: "black",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Line item name
            </div>
            <div
              style={{
                width: 200,
                height: 35,
                borderWidth: 1,
                borderColor: "black",
                borderRadius: 5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                onChange={(e) =>
                  setAddon({ ...addon, addonName: e.target.value })
                }
                style={{
                  width: 200,
                  height: "100%",
                  paddingLeft: 5,
                  borderColor: "black",
                  borderRadius: 5,
                  outline: "none",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                color: "black",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Description
            </div>
            <div
              style={{
                width: 300,
                height: 35,
                borderWidth: 1,
                borderColor: "black",
                borderRadius: 5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                onChange={(e) =>
                  setAddon({ ...addon, description: e.target.value })
                }
                style={{
                  width: 300,
                  height: "100%",
                  paddingLeft: 5,
                  borderColor: "black",
                  borderRadius: 5,
                  outline: "none",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginLeft: 10,
            }}
          >
            <div
              style={{
                color: "black",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Price
            </div>
            <div
              style={{
                width: 200,
                height: 35,
                borderWidth: 1,
                borderColor: "black",
                borderRadius: 5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                onChange={(e) =>
                  setAddon({ ...addon, price: Number(e.target.value) })
                }
                type="number"
                style={{
                  width: 200,
                  height: "100%",
                  paddingLeft: 5,
                  borderColor: "black",
                  borderRadius: 5,
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          borderBottomWidth: 1,
          borderBottomColor: "black",
          marginTop: 10,
        }}
      >
        <div style={{ width: "20%" }}>Name</div>
        <div style={{ width: "60%" }}>Description</div>
        <div style={{ width: "20%" }}>Price</div>
      </div>
      <div style={{ marginTop: 3 }}>
        {addons.addons.length > 0 ? (
          addons.addons.map((addon, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                borderBottomWidth: 1,
                borderBottomColor: "#ededed",
                marginBottom: 10,
              }}
            >
              <div
                key={index}
                style={{
                  color: "black",
                  width: "20%",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {addon.addonName}
              </div>
              <div
                key={index}
                style={{
                  color: "black",
                  width: "60%",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {addon.description}
              </div>
              <div
                key={index}
                style={{
                  color: "black",
                  width: "20%",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                ${addon.price}
              </div>
            </div>
          ))
        ) : (
          <div style={{ color: "black" }}>No add-ons created yet</div>
        )}
      </div>
    </div>
  );
}
