/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, ChkInputBox, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import * as React from "react";
import { Container } from "../../../../globalstyled";

import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import plusIcon from "../../../../images/PlusIcon.png";
import editIcon from "../../../../images/EditIcon.png";

import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import ContractorDrawer from "../SideDrawer/index";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";
import {
  ArrowIcon,
  FormTitleTypography,
  StandardIcon,
} from "../itemCreation.styled";
import TableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import { uploadImage } from "../../../../redux/api/common";
import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import {
  CONTRACTORS_TABLE_HEADER_LIST,
  PHOTOS_ROW_HEADERS,
  TRADE_TABLE_VIEW_ROW_HEADER_LIST,
  FILE_TABLE_HEADER_LIST,
  TRADES_TABLE_HEADER_LIST,
} from "../../../../contants/dashboardHeader";

import UpArrowIcon from "../../../../images/UploadIcon.png";
import DownArrowIcon from "../../../../images/DownloadIcon.png";

import {
  ContractorFile,
  Contractors,
  ProductSystem,
} from "../../../../redux/types/creation";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SFileUpload } from "../../../../components/input/input.styled";
import { FileOpen } from "@mui/icons-material";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import Pagination from "../ItemCreationComponent/Pagination";
import MaterialItemsProductSystemTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import {
  CONTRACTOR_DRAWER_DETAILS,
  SELECT_CONTRACTOR_FILETYPE,
} from "../../../../contants/itemsCreation";
import EmptyTable from "../../../../components/table/EmptyTable";
import ProductSystemParentIdsTable from "../ItemCreationComponent/ProductSystemParentIdsTable";
import { useCreateContractor } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import { useCreateUploadUrl } from "../../../../apolloClient/hooks/general/createHook";
import {
  ContractorFileInput,
  ContractorTradeInput,
  CreateContractorInput,
  CreateUploadFileInput,
} from "../../../../types/graphQlQuery.interface";
import FileUploadTable from "../ItemCreationComponent/FileUploadTable";

interface ContractorsTableProps {
  contractorsData: Contractors[];
  refetchContractors?: Function;
  productsIds?: ProductSystem[];
}

export const ContractorsTable: React.FC<ContractorsTableProps> = ({
  contractorsData,
  refetchContractors,
  productsIds,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openPhotoFormDrawer, setOpenPhotoFormDrawer] = React.useState(false);

  const [openTradeFormDrawer, setOpenTradeFormDrawer] = React.useState(false);
  const [editTradeId, setEditTradeId] = React.useState<number | null>(null);
  const [photoFormDataArr, setPhotoFormDataArr] = React.useState<
    ContractorFileInput[]
  >([]);

  const [tradesFormDataArr, setTradesFormDataArr] = React.useState<
    ContractorTradeInput[]
  >([]);

  const [openRowDetailsDrawer, setOpenRowDetailsDrawer] =
    React.useState<boolean>(false);

  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<Contractors | null>(null);

  const [contractorFormField, setContractorFormField] =
    useState<CreateContractorInput>({
      name: "",
      website: "",
      description: "",
      files: [],
      trades: [],
      parentIds: [],
    });

  const [contractorPhotoFormField, setContractorPhotoFormField] =
    useState<ContractorFileInput>({
      name: "",
      description: "",
      fileType: null,
      url: "",
    });

  const [contractorEditPhotoDetails, setContractorEditPhotoDetails] =
    useState<ContractorFileInput>({
      name: "",
      description: "",
      fileType: null,
      url: "",
    });

  const [contractorEditTradeDetails, setContractorEditTradeDetails] =
    useState<ContractorTradeInput>({
      name: "",
      description: "",
      yearsOfDoing: null,
    });

  const [contractorTradesFormField, setContractorTradesFormField] =
    useState<ContractorTradeInput>({
      name: "",
      description: "",
      yearsOfDoing: null,
    });

  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [isEditPhotoDetails, setIsEditPhotoDetails] = useState<boolean>(false);
  const [isEditTradeDetails, setIsEditTradeDetails] = useState<boolean>(false);
  const [parentIds, setParentIds] = useState([]);

  const [fileUrl, setFileUrl] = useState<string>("");

  const { createContractor } = useCreateContractor();

  const { createUploadUrl } = useCreateUploadUrl();

  const [showProductSystemParent, setShowProductSystemParent] =
    useState<boolean>(false);

  const [checkboxProductSystemStates, setCheckboxProductSystemStates] =
    useState<{
      [key: number]: boolean;
    }>({});

  const getUplaodUrl = async () => {
    const input: CreateUploadFileInput = {
      contentType: "image/jpeg",
    };

    const success = await createUploadUrl(input);
    if (success) {
      setUploadUrl(success.url);
      setFileUrl(success.fileName);
      return;
    } else {
      console.error("Failed to create Upload url.");
    }
  };

  const handleOpenFileDrawer = () => {
    setOpenPhotoFormDrawer(true);
    if (isEditPhotoDetails) {
      setContractorPhotoFormField({
        name: "",
        description: "",
        url: "",
        fileType: null,
      });
      setIsEditPhotoDetails(false);
    }
  };

  const handleOpenTradesDrawer = () => {
    setOpenTradeFormDrawer(true);
    if (isEditTradeDetails) {
      setContractorTradesFormField({
        name: "",
        description: "",
        yearsOfDoing: null,
      });
      setIsEditTradeDetails(false);
    }
  };

  const handleSelectFileType = (event, selectedFileType) => {
    if (selectedFileType) {
      setContractorPhotoFormField((prevItem) => ({
        ...prevItem,
        fileType: selectedFileType.label,
      }));
    }
  };

  const handlePhotoFormData = async () => {
    const file = fileRef.current?.files[0];

    const validationRules = [
      {
        condition: !contractorPhotoFormField.name,
        message: "File name is required",
      },
      {
        condition: !contractorPhotoFormField.description,
        message: "File description is required",
      },
      {
        condition: !contractorPhotoFormField.fileType,
        message: "File fileType is required",
      },
      {
        condition: !file,
        message: "A file have not been selected",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    try {
      const result = await uploadImage(uploadUrl, file);
      if (result.success) {
        let photoFormData: ContractorFileInput = {
          name: contractorPhotoFormField.name,
          description: contractorPhotoFormField.description,
          url: fileUrl,
          fileType: contractorPhotoFormField.fileType,
        };

        setPhotoFormDataArr((prevPhotoFormData) => [
          ...prevPhotoFormData,
          photoFormData,
        ]);

        setContractorPhotoFormField({
          name: "",
          description: "",
          url: "",
          fileType: null,
        });
        setOpenPhotoFormDrawer(false);
        setOpenPhotoFormDrawer(false);
        setUploadUrl("");
      }
    } catch (error) {
      console.error("Error uploading image ", error);
    }
  };

  const handleTradeFormData = () => {
    const validationRules = [
      {
        condition: !contractorTradesFormField.name,
        message: "Trade name is required",
      },
      {
        condition: !contractorTradesFormField.description,
        message: "Trade description is required",
      },
      {
        condition: !contractorTradesFormField.yearsOfDoing,
        message: "Years of Doing Trade is required",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    let tradeFormData: ContractorTradeInput = {
      name: contractorTradesFormField.name,
      description: contractorTradesFormField.description,
      yearsOfDoing: contractorTradesFormField.yearsOfDoing,
    };

    setTradesFormDataArr((prevTradeFormData) => [
      ...prevTradeFormData,
      tradeFormData,
    ]);

    setContractorTradesFormField({
      name: "",
      description: "",
      yearsOfDoing: null,
    });
    setOpenTradeFormDrawer(false);
  };

  const viewRowDetails = (rowData: Contractors) => {
    setViewRowDetailsData(rowData);
    handleViewRowDetailsDrawerOpen();
  };

  const handleViewRowDetailsDrawerOpen = () => {
    setOpenRowDetailsDrawer(true);
  };

  const handleViewRowDetailsDrawerClose = () => {
    setOpenRowDetailsDrawer(false);
  };

  const handleSubmitEditPhotoFormData = async () => {
    const file = fileRef.current?.files[0];

    try {
      if (file) {
        const result = await uploadImage(uploadUrl, file);
        if (result.success) {
          let photoFormData: ContractorFileInput = {
            name: contractorPhotoFormField.name,
            description: contractorPhotoFormField.description,
            url: fileUrl,
            fileType: contractorPhotoFormField.fileType,
          };

          const updatedPhotoFormDataArr = photoFormDataArr.filter(
            (photoData) => photoData.url !== contractorEditPhotoDetails.url
          );

          updatedPhotoFormDataArr.push(photoFormData);

          setPhotoFormDataArr(updatedPhotoFormDataArr);

          setContractorPhotoFormField({
            name: "",
            description: "",
            url: "",
            fileType: null,
          });
          setOpenPhotoFormDrawer(false);
          setUploadUrl("");
          setIsEditPhotoDetails(false);
        }
      } else {
        let photoFormData: ContractorFileInput = {
          name: contractorPhotoFormField.name,
          description: contractorPhotoFormField.description,
          url: contractorEditPhotoDetails.url,
          fileType: contractorPhotoFormField.fileType,
        };

        const updatedPhotoFormDataArr = photoFormDataArr.filter(
          (photoData) => photoData.url !== contractorEditPhotoDetails.url
        );

        updatedPhotoFormDataArr.push(photoFormData);

        setPhotoFormDataArr(updatedPhotoFormDataArr);

        setContractorPhotoFormField({
          name: "",
          description: "",
          url: "",
          fileType: null,
        });
        setOpenPhotoFormDrawer(false);
        setUploadUrl("");
        setIsEditPhotoDetails(false);
      }
    } catch (error) {
      console.error("Error uploading image ", error);
    }
  };

  const handleCancelFileForm = () => {
    setOpenPhotoFormDrawer(false);
    setUploadUrl("");
    setIsEditPhotoDetails(false);
    setContractorPhotoFormField({
      name: "",
      description: "",
      url: "",
      fileType: null,
    });
  };

  const handleCancelTradeForm = () => {
    setContractorTradesFormField({
      name: "",
      description: "",
      yearsOfDoing: null,
    });
    setOpenTradeFormDrawer(false);
    setIsEditTradeDetails(false);
    setEditTradeId(null);
  };

  const handleSubmitEditTradeFormData = () => {
    let tradeFormData: ContractorTradeInput = {
      name: contractorTradesFormField.name,
      description: contractorTradesFormField.description,
      yearsOfDoing: contractorTradesFormField.yearsOfDoing,
    };

    const updatedTradeFormDataArr = tradesFormDataArr.filter(
      (tradeData, index) => index !== editTradeId
    );

    updatedTradeFormDataArr.push(tradeFormData);

    setTradesFormDataArr(updatedTradeFormDataArr);

    setContractorTradesFormField({
      name: "",
      description: "",
      yearsOfDoing: null,
    });
    setOpenTradeFormDrawer(false);
    setIsEditTradeDetails(false);
    setEditTradeId(null);
  };

  const handleEditPhotoFormData = (rowData: ContractorFileInput) => {
    const { name, description, url, fileType } = rowData;
    setContractorEditPhotoDetails(rowData);
    setContractorPhotoFormField({
      name,
      description,
      url,
      fileType,
    });
    setOpenPhotoFormDrawer(true);
    setIsEditPhotoDetails(true);
  };

  const handleEditTradeFormData = (
    rowData: ContractorTradeInput,
    id: number
  ) => {
    const { name, description, yearsOfDoing } = rowData;
    setContractorEditTradeDetails(rowData);
    setContractorTradesFormField({
      name,
      description,
      yearsOfDoing,
    });
    setOpenTradeFormDrawer(true);
    setIsEditTradeDetails(true);
    setEditTradeId(id);
  };

  const handleChange = (value: string, name: string) => {
    setContractorFormField((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handlePhotoChange = (value: string, name: string) => {
    setContractorPhotoFormField((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleTradeChange = (value: string, name: string) => {
    setContractorTradesFormField((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterContractors = () => {
    if (keyword.length > 0 && Array.isArray(contractorsData)) {
      return contractorsData.filter((item) => {
        const contractorsName = `${item.name}`;
        const contractorsWebsite = `${item.website}`;
        return (
          contractorsName.toLowerCase().includes(keyword.toLowerCase()) ||
          contractorsWebsite.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    }
    return contractorsData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterContractors().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !contractorFormField.name,
        message: "Name is required",
      },
      {
        condition: !contractorFormField.website,
        message: "Website is required",
      },
      {
        condition: !contractorFormField.description,
        message: "Description is required",
      },
      {
        condition: photoFormDataArr.length === 0,
        message: "File is yet to be added",
      },
      {
        condition: tradesFormDataArr.length === 0,
        message: "Trade is yet to be added",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const input: CreateContractorInput = {
      name: contractorFormField.name,
      website: contractorFormField.website,
      description: contractorFormField.description,
      files: photoFormDataArr,
      trades: tradesFormDataArr,
      parentIds: parentIds,
    };

    const success = await createContractor(input);

    if (success) {
      toast("Contractor created successfully.", {
        type: "success",
        className: "toast-message",
        position: "top-center",
      });
      refetchContractors();
      handleDrawerClose();
      setContractorFormField({
        name: "",
        website: "",
        description: "",
        files: [],
        trades: [],
        parentIds: [],
      });
      setPhotoFormDataArr([]);
      setTradesFormDataArr([]);
      return;
    } else {
      console.error("Failed to create contractor.");
    }
  };

  const createContractorsForm = () => {
    return (
      <ContractorDrawer open={openDrawer} onClose={handleDrawerClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new contractor"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Name</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter name"
                  onChange={handleChange}
                  required={true}
                  value={contractorFormField.name}
                  name="name"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Website</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter contractor's website"
                  onChange={handleChange}
                  required={true}
                  value={contractorFormField.website}
                  name="website"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Description</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter public description"
                  onChange={handleChange}
                  required={true}
                  value={contractorFormField.description}
                  name="description"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Files</FormTitleTypography>
                  <StandardIcon src={plusIcon} onClick={handleOpenFileDrawer} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "10px",
                  }}
                >
                  {photoFormDataArr.length > 0 && (
                    <LeadsTableHeaderCard
                      headers={FILE_TABLE_HEADER_LIST}
                      fontSize={"14px"}
                    />
                  )}

                  {photoFormDataArr.map((contractor, contractorIndex) => (
                    <TableWithoutHeader
                      key={`Contractor file ${contractorIndex}`}
                      containerWidth="100%"
                      bodyContent={[
                        {
                          text: `${contractorIndex + 1}`,
                          width: "10%",
                          fontSize: "12px",
                        },
                        {
                          text: `${contractor.name}`,
                          width: "15%",
                          fontSize: "12px",
                        },
                        {
                          text: `${contractor.description}`,
                          width: "30%",
                          fontSize: "12px",
                        },
                        {
                          text: `${contractor.fileType}`,
                          width: "20%",
                        },
                        {
                          text: (
                            <a
                              href={`${contractor.url}`}
                              target="_blank"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                fontSize: 15,
                                color: AppCSS.GLAS_BLUE,
                                backgroundColor: AppCSS.GREEN_MILD,
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                              rel="noreferrer"
                              download={false}
                            >
                              <p>View File</p>
                            </a>
                          ),
                          width: "15%",
                        },
                        {
                          text: "",
                          width: "10%",
                          justifyContent: "end",
                          isAction: true,
                          actionComponent: (
                            <>
                              <Box
                                onClick={() => {
                                  handleEditPhotoFormData(contractor);
                                }}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingRight: "20px",
                                  cursor: "pointer",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <StandardIcon src={editIcon} />
                              </Box>
                            </>
                          ),
                        },
                      ]}
                    />
                  ))}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Trades</FormTitleTypography>
                  <StandardIcon
                    src={plusIcon}
                    onClick={handleOpenTradesDrawer}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "10px",
                  }}
                >
                  {tradesFormDataArr.length > 0 && (
                    <LeadsTableHeaderCard
                      headers={TRADES_TABLE_HEADER_LIST}
                      fontSize={"14px"}
                    />
                  )}
                  {tradesFormDataArr.map((trade, tradeIndex) => (
                    <TableWithoutHeader
                      key={`trade ${tradeIndex}`}
                      containerWidth="100%"
                      bodyContent={[
                        {
                          text: `${tradeIndex + 1}`,
                          width: "10%",
                          fontSize: "12px",
                        },
                        {
                          text: `${trade.name}`,
                          width: "15%",
                          fontSize: "12px",
                        },
                        {
                          text: `${trade.description}`,
                          width: "30%",
                          fontSize: "12px",
                        },
                        {
                          text: `${trade.yearsOfDoing}`,
                          width: "30%",
                          fontSize: "12px",
                        },
                        {
                          text: "",
                          width: "15%",
                          justifyContent: "end",
                          isAction: true,
                          actionComponent: (
                            <>
                              <Box
                                onClick={() => {
                                  handleEditTradeFormData(trade, tradeIndex);
                                }}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingRight: "20px",
                                  cursor: "pointer",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <StandardIcon src={editIcon} />
                              </Box>
                            </>
                          ),
                        },
                      ]}
                    />
                  ))}
                </Box>

                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Lbl
              title="Associated product systems"
              size={16}
              width={80}
              bold={600}
            />
          </Box>

          <Box
            sx={{
              width: "96%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              background: "inherit",
              borderRadius: "10px",
              border: "1px solid rgba(173, 173, 173, 1)",
              height: "fit-content",
              minHeight: "50px",
              paddingY: "10px",
            }}
            onClick={() => setShowProductSystemParent(!showProductSystemParent)}
          >
            <RowDiv
              style={{
                width: "100%",
                paddingLeft: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Lbl
                  title="Select associated product systems"
                  size={16}
                  mobileFontSize={16}
                  isFullWidth={true}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "40%",
                  alignItems: "center",
                  justifyContent: "end",
                  paddingRight: "20px",
                }}
              >
                {showProductSystemParent ? (
                  <ArrowIcon src={UpArrowIcon} />
                ) : (
                  <ArrowIcon src={DownArrowIcon} />
                )}
              </Box>
            </RowDiv>

            {showProductSystemParent && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "start",
                  width: "100%",
                  paddingX: "20px",
                }}
              >
                {productsIds.map((item) => (
                  <Box
                    key={`checkProductsSystemParent misc ${item.id}`}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <ChkInputBox
                      onChange={(value: boolean) => {
                        setCheckboxProductSystemStates(
                          (prevCheckboxStates) => ({
                            ...prevCheckboxStates,
                            [item.id]: value,
                          })
                        );
                        setShowProductSystemParent(true);
                      }}
                      placeholder=""
                      width={50}
                      marginTop={0}
                      marginBottom={0}
                      height={20}
                      background="inherit"
                      checked={checkboxProductSystemStates[item.id] || false}
                    />
                    <Typography>{item.name}</Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
      </ContractorDrawer>
    );
  };

  const createPhotoForm = () => {
    return (
      <ContractorDrawer
        open={openPhotoFormDrawer}
        onClose={() => {
          setOpenPhotoFormDrawer(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={() => {
              setOpenPhotoFormDrawer(false);
            }}
            labelTitle={
              isEditPhotoDetails ? "Edit Photo Details" : "Add contractor photo"
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Photo name</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter name"
                  onChange={handlePhotoChange}
                  required={true}
                  value={contractorPhotoFormField.name}
                  name="name"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Photo description</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter description"
                  onChange={handlePhotoChange}
                  required={true}
                  value={contractorPhotoFormField.description}
                  name="description"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <FormTitleTypography
                  style={{
                    display: "felx",
                    width: "60%",
                    justifyContent: "start",
                    textAlign: "start",
                  }}
                >
                  Select File Type
                </FormTitleTypography>

                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "inherit",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={SELECT_CONTRACTOR_FILETYPE}
                      sx={{ width: "100%" }}
                      onChange={handleSelectFileType}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Upload File</FormTitleTypography>
                </Box>

                <Box
                  sx={{
                    width: "96%",
                    marginX: "2%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",

                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lbl
                      title={
                        uploadUrl !== "" ? "Photo uploaded" : "Upload file"
                      }
                      size={16}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SFileUpload
                      style={{
                        border: "none",
                        marginLeft: 10,
                        height: "35px",
                        width: "100px",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        if (fileRef.current) {
                          fileRef.current.click();
                        }
                      }}
                    >
                      <input
                        type="file"
                        id="file"
                        ref={fileRef}
                        onChange={() => {
                          if (uploadUrl === "") {
                            getUplaodUrl();
                          }
                        }}
                        accept={"image/png, image/jpeg, image/jpg,.pdf"}
                        style={{
                          display: "none",
                        }}
                      />
                      <FileOpen
                        titleAccess="Select File"
                        color="warning"
                        style={{
                          width: 32,
                          height: 32,
                          color: AppCSS.GLAS_GREEN,
                        }}
                      />
                    </SFileUpload>
                  </Box>
                </Box>
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleCancelFileForm}
          onCreate={
            isEditPhotoDetails
              ? handleSubmitEditPhotoFormData
              : handlePhotoFormData
          }
          submitText={"Save"}
        />
      </ContractorDrawer>
    );
  };

  const createTradeForm = () => {
    return (
      <ContractorDrawer
        open={openTradeFormDrawer}
        onClose={() => {
          setOpenTradeFormDrawer(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={() => {
              setOpenTradeFormDrawer(false);
            }}
            labelTitle={
              isEditTradeDetails ? "Edit Trade Details" : "Add contractor trade"
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <ColDiv
              style={{
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                {" "}
                <FormTitleTypography>Trade Name</FormTitleTypography>
              </Box>
              <TxtInput
                placeholder="Enter name"
                onChange={handleTradeChange}
                required={true}
                value={contractorTradesFormField.name}
                name="name"
                background="inherit"
                borderColor="rgba(173, 173, 173, 1)"
                borderRadius={10}
                height={50}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                {" "}
                <FormTitleTypography>Trade Description</FormTitleTypography>
              </Box>
              <TxtInput
                placeholder="Enter description"
                onChange={handleTradeChange}
                required={true}
                value={contractorTradesFormField.description}
                name="description"
                background="inherit"
                borderColor="rgba(173, 173, 173, 1)"
                borderRadius={10}
                height={50}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                {" "}
                <FormTitleTypography>Years Doing Trade</FormTitleTypography>
              </Box>
              <TxtInput
                placeholder="Enter number of years"
                onChange={handleTradeChange}
                required={true}
                value={contractorTradesFormField.yearsOfDoing}
                name="yearsOfDoing"
                background="inherit"
                borderColor="rgba(173, 173, 173, 1)"
                borderRadius={10}
                height={50}
                type="number"
              />

              <Spacer size={1} direction="col" />
            </ColDiv>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleCancelTradeForm}
          onCreate={
            isEditTradeDetails
              ? handleSubmitEditTradeFormData
              : handleTradeFormData
          }
          submitText={"Save"}
        />
      </ContractorDrawer>
    );
  };

  const TradesTable = ({ value, key }: { value: any[]; key: String }) => (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <LeadsTableHeaderCard
        headers={TRADE_TABLE_VIEW_ROW_HEADER_LIST}
        fontSize={"14px"}
      />
      {value.map((trade: any, index: number) => (
        <MaterialItemsProductSystemTableWithoutHeader
          key={`${key} trade_${index}`}
          bodyContent={[
            {
              text: `${trade.id}`,
              width: "5%",
              fontSize: "12px",
            },
            {
              text: `${trade.name}`,
              width: "25%",
              fontSize: "12px",
            },
            {
              text: ` ${trade.description}`,
              width: "40%",
              fontSize: "12px",
            },
            {
              text: `${trade.yearsOfDoing}`,
              width: "30%",
              fontSize: "12px",
            },
          ]}
        />
      ))}
    </Box>
  );

  const showContractorsRowDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openRowDetailsDrawer}
        handleDrawerClose={handleViewRowDetailsDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Contractor Details"
        rowArrayTableComponent={(value: ContractorFile[], key?: string) => (
          <FileUploadTable value={value} key={key} />
        )}
        rowArrayTableComponentTwo={(value: ContractorFile[], key?: string) => (
          <TradesTable value={value} key={key} />
        )}
        detailsOfRowToBeDisplay={CONTRACTOR_DRAWER_DETAILS}
      />
    );
  };

  const ContractorsTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search contractors"
            />

            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard headers={CONTRACTORS_TABLE_HEADER_LIST} />
            </Box>
          </ColDiv>

          {filterContractors().length === 0 ? (
            <EmptyTable message="No Contractor available" />
          ) : (
            filterContractors()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((contractor, contractorIndex) => (
                <LeadsTableBodyCard
                  key={`Contractor ${contractor.id}`}
                  containerWidth="100%"
                  bodyContent={[
                    {
                      text: `${contractor.id}`,
                      width: "10%",
                      fontSize: "15px",
                      paddingLeft: "30px",
                    },
                    {
                      text: `${contractor.name}`,
                      width: "20%",
                      fontSize: "15px",
                    },
                    {
                      text: `${contractor.website}`,
                      width: "20%",
                      fontSize: "15px",
                    },
                    {
                      text: `${contractor.description}`,
                      width: "35%",
                      fontSize: "15px",
                    },
                    {
                      text: "",
                      width: "15%",
                      isAction: true,
                      justifyContent: "end",
                      actionComponent: (
                        <TableViewDetails
                          rowData={contractor}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterContractors}
        />
      </Container>
    );
  };

  const contractorsContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(contractorsData) && ContractorsTable()}
        </ColDiv>
        {createContractorsForm()}
        {showContractorsRowDetails()}
        {createPhotoForm()}
        {createTradeForm()}
      </Container>
    );
  };

  return contractorsContainer();
};
