import React from "react";
import CompletionButton from "./CompletionButton";

interface StarButtonProps {
  quality: number;
  onButtonClick: () => void;
}

const StarButton: React.FC<StarButtonProps> = ({ quality, onButtonClick }) => {
  const getStarImages = (quality: number) => {
    const starImages: string[] = [];
    for (let i = 0; i < 3; i++) {
      starImages.push(i < quality ? "/darkStar.png" : "/lightStar.png");
    }
    return starImages;
  };

  const starImages = getStarImages(quality);

  return (
    <CompletionButton
      onClick={onButtonClick}
      hoverBgColor="#B0C4DE"
      bgColor="inherit"
      width="155px"
      textColor="#000000"
      boxShadow="none"
      borderRadius="10px"
      height="24px"
      fontSize="15px"
      fontWeight="300"
      disable={true}>
      <div className="flex flex-row justify-around items-center w-full h-full text-[#000000] ">
        <p className="text-[12px] text-[#000000] font-bold">Quality</p>
        {starImages.map((src, index) => (
          <img
            key={index}
            src={src}
            width={15}
            height={15}
            alt={`Star ${index + 1}`}
            className="ml-1 "
          />
        ))}
      </div>
    </CompletionButton>
  );
};

export default StarButton;
