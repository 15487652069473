import CompletionButton from "./CompletionButton";
import Carousel from "./Carousel";
import StarButton from "./QualityStarDiv";

const ShingleMaterialCard = ({
  name,
  brand,
  description,
  price,
  quality,
  measurementUnit,
  onDelete,
}) => {
  return (
    <div className="flex flex-col w-[346px] h-[269px] rounded-[10px] gap-1 border-[2px] border-[#D5D5D5] justify-start items-start px-5 py-2.5 bg-[inherit] shadow-custom">
      <div className="flex flex-row w-full justify-between items-start ">
        <div className="flex flex-col w-[160px] justify-start items-start gap-1">
          <h2 className="text-black text-[15px] leading-[17.6px]">{name}</h2>
        </div>

        <CompletionButton
          onClick={onDelete}
          bgColor={"#000000"}
          width="120px"
          height="20px"
          textColor={"#FFFFFF"}
          borderRadius="10px"
          borderColor="#000000"
          fontSize="12px">
          Delete
        </CompletionButton>
      </div>
      <div className="flex flex-col w-full justify-start items-start pl-4 ">
        <div className="flex flex-row w-full justify-start items-start gap-1">
          <h2 className="text-black text-[15px]">Brand:</h2>
          <h2 className="text-black text-[15px]">{brand}</h2>
        </div>
        <div className="flex flex-row w-full justify-start items-start gap-1">
          <h2 className="text-black text-[15px]">Description:</h2>
          <h2 className="text-black text-[15px]">{description}</h2>
        </div>
        <div className="flex flex-row w-full justify-start items-start gap-1">
          <h2 className="text-black text-[15px]">Price:</h2>
          <h2 className="text-black text-[15px]">
            {price} per {measurementUnit}
          </h2>
        </div>
      </div>
      <div className="flex flex-col w-full justify-center items-center ">
        <Carousel
          images={[
            "/shingleImageOne.png",
            "/visible.png",
            "/shingleImageTwo.png",
            "/information.png",
          ]}
        />
      </div>
      <div className="flex flex-row w-full justify-start items-start gap-2 ">
        <StarButton quality={quality} onButtonClick={() => {}} />
        <CompletionButton
          onClick={() => {}}
          hoverBgColor="#B0C4DE"
          bgColor="inherit"
          width="155px"
          textColor="#000000"
          boxShadow="none"
          borderRadius="10px"
          height="24px"
          fontSize="15px"
          fontWeight="300"
          disable={true} // Change to false if you want the button to be clickable
          borderColor="inherit">
          <div className="flex flex-row justify-around items-center w-full h-full">
            <p className="text-[12px]">View Warranty</p>
            <img
              src="/viewWarranty.png"
              width={12}
              height={12}
              alt="view warranty"
            />
          </div>
        </CompletionButton>
      </div>
    </div>
  );
};

export default ShingleMaterialCard;
