/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";

type CarouselProps = {
  images: string[];
};

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToImage = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-[346px] p-4">
      <div className="flex overflow-hidden h-[71px] justify-center items-center">
        {images.slice(currentIndex, currentIndex + 2).map((image, index) => (
          <div
            key={index}
            className="relative w-[128px] h-[71px] flex-shrink-0 mr-4">
            <img
              src={image}
              alt={`Carousel Image ${currentIndex + index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`inline-block w-2 h-2 mx-1 rounded-full cursor-pointer ${
              index === currentIndex || index === currentIndex + 1
                ? "bg-gray-800"
                : "bg-gray-300"
            }`}
            onClick={() => goToImage(index)}></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
