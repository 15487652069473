import { FC, useState } from "react";
import { ColDiv } from "../../../../components/misc/misc.styled";
import { ProjectProductMeterial } from "../../../../redux/types/Material";
import ShingleMaterialCard from "../../../../components/ui/ShingleMaterialCard";

interface MaterialProps {
  materials?: ProjectProductMeterial[]; // Optional prop
}

export const MaterialDetails: FC<MaterialProps> = ({ materials }) => {
  const [materialList, setMaterialList] = useState<ProjectProductMeterial[]>(
    materials || [] // Initialize with an empty array if materials is undefined
  );

  const deleteMaterial = (index: number) => {
    setMaterialList((prevMaterials) =>
      prevMaterials.filter((_, i) => i !== index)
    );
    console.log("Material deleted");
  };

  return (
    <>
      <ColDiv
        style={{
          width: "95%",
          marginBottom: "1%",
          gap: "10px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}>
        <div className="flex w-full flex-col justify-start items-start gap-4 h-[620px] pb-2">
          {materialList.length === 0 ? (
            <div className="text-center text-gray-500">No data to display.</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              {materialList.map((product, productIndex) =>
                product.materials && product.materials.length > 0 ? (
                  product.materials.map((material, index) => (
                    <ShingleMaterialCard
                      key={`${productIndex}-${index}`}
                      {...material}
                      onDelete={() => deleteMaterial(productIndex)}
                    />
                  ))
                ) : (
                  <div key={productIndex} className="text-center text-gray-500">
                    No materials available for {product.name}.
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </ColDiv>
    </>
  );
};
